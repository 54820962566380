<template>
  <div>
    <b-card border-variant="danger" v-if="errore_caricamento">
      <b-row class="match-height">
        <b-col
          md="8"
        >
          <h4 class="mt-0 mb-0 text-danger">Errore durante il caricamento delle informazioni iniziali</h4>
          <p>Ti preghiamo di segnalare il problema all'assistenza tecnica, grazie</p>
        </b-col>
        <b-col 
          md="4"
          class="text-center"
        >
          <b-button
          variant="primary"
          :to="{ name: 'home-agentscout' }"
          >
              <feather-icon
                  icon="CornerUpLeftIcon"
                  size="16"
                  class="mr-1"
              />Home
          </b-button>
        </b-col>
      </b-row>
        
    </b-card>
    
    <div class="text-center my-3" v-if="Caricato == false">
        <b-spinner
            variant="primary"
            style="width: 3rem; height: 3rem;"
            class="mr-1"
            label="loading"
        />
    </div>

    <b-card bg-variant="Default" v-if="Caricato">
        <b-card-body class="px-0 py-0">
            <pre>{{ this.dati }}</pre>
        
        </b-card-body>
    </b-card>

      
  </div>
</template>

<script>
import router from '@/router'
import { BTabs, BTab, BRow, BCol, BCard, BCardTitle, BCardHeader, BCardBody, BCardText, BSpinner, BButton, BSidebar, VBToggle, BBadge } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import showdata from '@core/components/showdata/showdata.vue'

export default {
  components: {
    BTabs,
    BTab,
    BRow,
    BCol,
    BCard, 
    BCardTitle, 
    BCardHeader, 
    BCardBody, 
    BCardText, 
    BSpinner, 
    BButton,
    BSidebar, 
    VBToggle, 
    BBadge,

    showdata,
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  data() {
    return {
      Caricato: false,
      errore_caricamento: false,
      id_licenza: router.currentRoute.params.id_riga,
      dati: [],
      stato_licenza: 'da_accettare',
    }
  },
  created() {
    this.$http.get('api/crm/license/card/'+this.id_licenza).then(response => {
      if(response.status===200){
        //ok dati ricevuti
        // check autorizzazione ad accedere ai dati oppure no
        if(response.data.statusCode===200){
          //ok autorizzato ! => proseguo
          this.dati = response.data.reply

          //console.log("FINE --------------------")
          //console.log(this.dati)

          /*
          if(this.dati.licenseData.contract.accepted_at === null){
            //licenza da accettare da parte del cliente
            this.stato_licenza = 'da_accettare'
          } else {
            if(this.dati.licenseData.status.id == '20'){
              this.stato_licenza = 'accettato'
            } else {
              //già pagata -> stati succesivi
              this.stato_licenza = 'gia_pagato'

            }
          }
          */

          if(this.dati.licenseData.subscription !== null){
            if((this.dati.licenseData.subscription.item.stripe_product !== null) && (this.dati.licenseData.subscription.item.stripe_product != '')){
              //carico i dati del prodotto Stripe associato alla licenza

              //console.log("this.dati.subscription.item.stripe_product -> "+this.dati.licenseData.subscription.item.stripe_product)

              this.$http.get('api/crm/stripe/product/card/'+this.dati.licenseData.subscription.item.stripe_product).then(response => {
                if(response.status===200){
                  //ok dati ricevuti
                  // check autorizzazione ad accedere ai dati oppure no
                  if(response.data.statusCode===200){
                    //ok autorizzato ! => proseguo
                    this.dati.licenseData.productStripe = response.data.reply
                    
                    this.Caricato = true;
                    
                  } else {
                    //accesso a contratto che non è il tuo! => accesso "Unauthenticated" 
                    this.Caricato = false;
                    this.errore_caricamento = true;
                  }
                } else {
                  if(response.status===401){
                    //accesso "Unauthenticated" -> accade quando si sviene sloggati dall'api
                    console.error("Errore api -> "+response.data.reply['message'])

                    if(logoutGeneral() === true){
                      // Redirect to login page
                      //this.$router.push({ name: 'auth-login' })
                      this.$router.replace('/login?status=again')
                    }
                  }

                  this.Caricato = false;
                  this.errore_caricamento = true;
                }
              
              }).catch(e => {
                this.Caricato = false;
                this.errore_caricamento = true;
              });

            } else {
              //niente -> fine
              this.Caricato = true;
            }
          } else {
            //niente -> fine
            this.Caricato = true;
          }
          
        } else {
          //accesso a contratto che non è il tuo! => accesso "Unauthenticated" 
          this.Caricato = false;
          this.errore_caricamento = true;
        }
      } else {
        if(response.status===401){
          //accesso "Unauthenticated" -> accade quando si sviene sloggati dall'api
          console.error("Errore api -> "+response.data.reply['message'])

          if(logoutGeneral() === true){
            // Redirect to login page
            //this.$router.push({ name: 'auth-login' })
            this.$router.replace('/login?status=again')
          }
        }

        this.Caricato = false;
        this.errore_caricamento = true;
      }
    
    }).catch(e => {
      /*
      this.Caricato = false;
      this.errore_caricamento = true;
      */

      //console.log("Errore api ......")
      //console.log(e)

      this.Caricato = false;
      this.errore_caricamento = true;

    });

  },
  methods: {

  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
